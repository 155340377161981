import React from "react";
import SEO from "../components/seo";
import appStore from "../images/v2/download-on-the-app-store.svg";
import Image from "../components/image";
import SignUpButton from "../components/SignUpButton";
import integrationsSvg from "../images/integrations.svg";

const Testimonial = ({ name, role, content, avatar, logo }) => {
  return <div className={`shadow-2xl radius-8 rounded-xl p-8 relative`}>
    <div className={"flex flex-row items-center"}>
      <Image imgName={avatar} style={{ width: 100, height: 100 }} />
      <div className={"flex-1 pl-4"}>
        <div className={"font-bold md:text-lg"}>{name}</div>
        <div className={"text-gray-400 text-sm"}>{role}</div>
      </div>
      <div className={"hidden md:block absolute right-0 top-0 p-8"}>
        {logo}
      </div>
    </div>
    <p className={"pt-4"}>
      {content}
    </p>
  </div>;
};

const TestimonialSection = ({ name, role, content, avatar, logo }) => {
  return <section className={"mt-16 xsm:mt-32 lg:px-24 xl:px-48 xxl:px-64 mb-32 container mx-auto relative"}>
    <div className={"max-w-4xl p-4 px-6 pb-0 mx-auto relative"}>
      <Testimonial name={name} role={role}
                   content={content}
                   avatar={avatar}
                   logo={logo}
      />
    </div>
  </section>;
};

const ResponsiveSignUpButton = ()=>{
  return <SignUpButton
    className={"w-2/3 sm:w-auto block sm:inline-block mx-auto my-4 sm:m-4 text-lg"}
  />
}

const IndexPage = ({ location }) => {
  return (
    <div className={"overflow-x-hidden"}>
      <SEO
        location={location}
        breadcrumb={"Home"}
        title="Agile Tool for Web and App Development Teams"
        description={
          "Lightweight agile management tool for software development teams and digital agencies managing more than one project with Scrum or Kanban."
        }
      />

      <section className={"mt-16 xsm:mt-32 md:mt-64 lg:px-16"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl flex flex-col-reverse md:flex-row p-4 px-6 pb-0 mx-auto"}>
            <div className={"w-full md:w-1/2 pb-8 relative z-1"}>
              <h1 className={"text-3xl md:text-4xl lg:text-5xl font-bold mb-4"}>
                Manage multiple projects with agile teams
              </h1>
              <p className={"text-lg"}>
                Scrum Mate adds flexibility to the powerful toolset of Scrum and Kanban in order to meet the needs of organizations working on multiple projects.
              </p>
              <div className={"text-right mt-8"}>
                <ResponsiveSignUpButton />
              </div>
            </div>
            <div className={"md:w-1/2 relative"} style={{ zIndex: -1 }}>
              <Image className={"-ml-8 md:-ml-32 md:-mt-32 -mr-16 md:-mr-64"} imgName={"manage-multiple-projects"}
                     alt={"Manage multiple projects"} />
            </div>
          </div>
        </div>
      </section>

      <section className={"mt-16 xsm:mt-32 md:mt-64 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl flex flex-col-reverse md:flex-row p-4 px-6 pb-0 mx-auto"}>
            <div className={"md:w-1/2 relative z-10"}>
              <Image className={"hidden md:block md:-ml-16 md:mt-16"} imgName={"combine-multiple-projects-1"} alt={"Manage multiple projects"} />
              <Image className={"md:hidden -mr-96 -ml-24"} imgName={"combine-multiple-projects-mobile"}
                     alt={"Distribute work across teams"} />
            </div>
            <div className={"w-full md:w-1/2 pb-8 relative z-0"}>
              <div className={"md:mb-16 md:px-8"}>
                <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4"}>Combine multiple projects into one team board</h1>
                <p className={"lg:text-lg"}>
                  Even with multiple projects across multiple clients, easily collect all your team's tasks into a single workflow. Allow the freedom for each team to apply their preferred framework Scrum or Kanban.
                </p>
              </div>
              <Image className={"hidden md:block -mr-96 -ml-16"} imgName={"combine-multiple-projects-2"}
                     alt={"Combine multiple projects into one team board"} />
            </div>
          </div>
        </div>
      </section>

      <section className={"mt-16 xsm:mt-32 md:mt-64 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl flex flex-col-reverse md:flex-row p-4 px-6 pb-0 mx-auto"}>
            <div className={"md:w-1/2 relative z-10"}>
              <div className={"mb-8 md:mb-16 pr-16"}>
                <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4"}>Distribute project work across teams</h1>
                <p className={"lg:text-lg"}>
                  Whether it's marketing, development, or design, invite any teams to contribute and collaborate on one project. You'll get a unified, real-time overview of the entire project as teams update tasks on their own boards.
                </p>
              </div>
              <Image className={"hidden md:block -mr-16 md:mt-16 -ml-4"} imgName={"distribute-tasks-across-boards-1"}
                     alt={"Distribute work across teams"} />
              <Image className={"md:hidden -mr-64 -ml-36"} imgName={"distribute-tasks-across-boards-mobile"}
                     alt={"Distribute work across teams"} />
            </div>
            <div className={"w-full md:w-1/2 pb-8 relative z-0"}>
              <Image className={"hidden md:block -mr-96 -ml-16 mt-16"} imgName={"distribute-tasks-across-boards-2"}
                     alt={"Distribute work across teams"} />
            </div>
          </div>
        </div>
      </section>

      <section className={"mt-16 xsm:mt-32 md:mt-64 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl p-4 md:px-12 pb-0 mx-auto"}>
            <div className={"mb-8"}>
              <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4 md:text-center"}>Improve client relationships by aligning on priorities
              </h1>
              <p className={"lg:text-lg md:text-center"}>Build a constructive relationship with your clients based on transparency and clear priority. Collect and organize all tasks in a shared project backlog and work in close collaboration with your clients.</p>
            </div>
            <Image className={"w-full"} imgName={"improve-client-relationship"} alt={"Improve client relationships"} />
          </div>
        </div>
      </section>

      <TestimonialSection name={"Jennifer Fanto"} role={"Senior Product Manager"}
                          content={"Not only is Scrummate a well-designed and easy to use Kanban tool, the development team provides service and support unlike any I've ever experienced. They are responsive both to customer questions/support needs as well as open to discussing and implementing upgrade recommendations from their users."}
                          avatar={"jennifer-fanto"}
                          logo={<Image style={{ width: 63, height: 35 }} imgName={"logo-dh"} />}
      />

      <section className={"mt-16 xsm:mt-32 md:mt-64 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl flex flex-col md:flex-row p-4 px-6 pb-0 mx-auto"}>
            <div className={"md:w-1/2 relative z-10"}>
              <Image className={"-mr-16 mb-16 -ml-4"} imgName={"agile-toolset"} alt={"All the standard agile toolset"} />
              <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4"}>...and all the standard agile toolset you expect</h1>
              <p className={"lg:text-lg"}>Work in a Kanban flow or run in Scrum sprints with a flexible, and lightweight board. Gather and
                prioritize product requirements with a simple and powerful backlog tool. Track progress with burndown charts and
                cummulative flow chart.</p>
              <div className={"text-right mt-8"}>
                <ResponsiveSignUpButton className={"block sm:inline"} />
              </div>
            </div>
            <div className={"w-full md:w-1/2 pb-8 relative z-0 md:pl-16 mt-16"}>
              <Image className={"md:mt-96"} imgName={"on-ios-and-web"} alt={"On iOS and Web"} />
              <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4"}>On iOS and Web</h1>
              <p className={"lg:text-lg"}>With real-time sync across platforms, to keep your people sync.
                Android coming soon...</p>
              <a
                href="https://apps.apple.com/us/app/scrum-mate/id1489611339"
                className={"mt-4"}
                aria-label={"Scrum Mate on App Store"}
                title={"Scrum Mate on App Store"}
                style={{
                  width: 135,
                  height: 40,
                  overflow: "hidden",
                  display: "block",
                  color: "transparent",
                  marginLeft: "auto"
                }}
              >
                <img src={appStore} alt={"Download on the App Store"} />
              </a>
            </div>
          </div>
        </div>
      </section>

      <TestimonialSection name={"Judit Halász"} role={"Senior Project Manager"}
                          content={"ScrumMate is simply the best tool for agile management. We tried quite a few different Scrum tools, but ScrumMate is by far the best and most easy to use. It's clean UI and functionality is on point and it's free of any unnecessary additions. Installation is easy and doesn't require long setups. It's very easy to train and involve new team members. Another huge plus is that it is under constant development, and we receive updates regularly :)"}
                          avatar={"judit-halasz"}
                          logo={<Image style={{ width: 116, height: 37 }} imgName={"logo-stylers"} />}
      />

      <section className={"md:mt-16 xsm:mt-32 md:mt-64 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl flex flex-col-reverse md:flex-row p-4 md:px-6 pb-0 mx-auto"}>
            <div className={"md:w-1/2 relative z-10"}>
              <Image className={"-mr-32 xsm:mr-0 md:-ml-16 md:mt-16"} imgName={"single-account"} alt={"One account"} />
            </div>
            <div className={"w-full md:w-1/2 pb-8 relative z-0 pt-24 lg:pt-32 md:pl-8 lg:pl-16"}>
              <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4"}>Different organizations, different subscriptions… one same account</h1>
              <p className={"lg:text-lg"}>Even if you're working across different organizations, you don't have to switch between accounts -  one account is all you need to access all your tasks on Scrum Mate.</p>
            </div>
          </div>
        </div>
      </section>

      <section className={"mt-16 xsm:mt-32 md:mt-64 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto relative"}>
          <div className={"max-w-6xl flex flex-col md:flex-row p-4 px-6 pb-0 mx-auto"}>
            <div className={"md:w-1/2 relative z-10 md:pt-32 lg:pt-64"}>
              <div className={"mb-8 md:pr-8"}>
                <h1 className={"text-2xl lg:text-3xl font-extrabold mb-4"}>Spend less time on timesheets</h1>
                <p className={"lg:text-lg"}>Does filling in and organizing a timesheet eat up your workday? Scrum Mate simplifies task time tracking, progress monitoring, and meeting log targets so you don't lose valuable billable hours.</p>
              </div>
            </div>
            <div className={"w-full md:w-1/2 pb-8 relative z-0"}>
              <Image className={"-mr-96"} imgName={"my-worklogs"} alt={"Spend less time on timesheets"} />
            </div>
          </div>
        </div>
      </section>

      <TestimonialSection name={"Steve Novak"} role={"Agile Coach"}
                          content={"Scrum Mate is by far my favorite agile management tool. It takes no more than a few minutes to set up a new project and a new team. So we can focus on creating value instead of implementing a management solution. Scrum Mate can easily keep up with the evolution of our clients, it can be scaled up to 50-100 members."}
                          avatar={"istvan-novak"}
                          logo={<Image style={{ width: 165, height: 37 }} imgName={"logo-preemptive"} />}
      />

      <section className={"sm:pb-16 md:pb-32 lg:px-24 xl:px-48 xxl:px-64"}>
        <div className={"container mx-auto flex flex-col-reverse md:flex-row"}>
          <div className={"md:w-1/2 flex flex-col justify-center mb-4 md:mb-0 px-6"}>
            <h1 className={"text-xl md:text-2xl lg:text-3xl font-extrabold pb-4"}>Integrate with your other tools</h1>
            <p>
              Embed Scrum Mate into your workflow with built-in integrations or build your own custom integration using our
              public API.
            </p>
            <div className={"w-full py-8 mx-auto text-right mt-8 md:mt-0"}>
              <ResponsiveSignUpButton/>
            </div>
          </div>
          <div className={"w-full -ml-16 md:w-1/2 md:p-8 md:p-0 xsm:-mb-16 sm:-mb-32"} style={{ zIndex: -1, minWidth: 600 }}>
            <img
              src={integrationsSvg}
              className={"min-w-screen ml-16 sm:ml-32 sm:ml-64 md:ml-0 md:mt-0"}
              alt={"Integrations"}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndexPage;
